import React, { useState } from "react";
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import { CallReceived } from "@mui/icons-material";
import PaymentsIcon from "@mui/icons-material/Payments";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import AssessmentIcon from "@mui/icons-material/Assessment";

const QuickLinksMenu = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [buttonWidth, setButtonWidth] = useState(0);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setButtonWidth(event.currentTarget.offsetWidth); // Capture button width
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      {/* Quick Links Button */}
      <Button
        variant="contained"
        endIcon={<KeyboardArrowDownIcon />}
        onClick={handleClick}
        sx={{
          textTransform: "none",
          fontSize: "18px",
          fontWeight: 600,
          backgroundColor: "#6200ea",
          color: "#fff",
          borderRadius: "24px",
          padding: "6px 10px",
          transition: "transform 0.3s",
          position: "relative",
          overflow: "hidden",
          "&:hover": {
            backgroundColor: "#5a00d1",
            transform: "scale(1.02)",
          },
          "&::after": {
            content: '""',
            position: "absolute",
            top: 0,
            left: "-100%",
            width: "200%",
            height: "100%",
            background:
              "linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.3), transparent)",
            transition: "0.5s",
          },
          "&:hover::after": {
            left: "100%",
          },
        }}
      >
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          {/* Static Icon */}
          <span
            role="img"
            aria-label="lightning"
            style={{
              fontSize: "20px",
              animation: "shine 1.5s infinite",
              "@keyframes shine": {
                "0%": { opacity: 0.7 },
                "50%": { opacity: 1 },
                "100%": { opacity: 0.7 },
              },
            }}
          >
            ⚡
          </span>

          {/* Gradient Animated Text */}
          <span
            // style={{
            //   background:
            //     "linear-gradient(90deg, #ff8a00, #e52e71, #5a00d1, #ff8a00)",
            //   backgroundSize: "300%",
            //   WebkitBackgroundClip: "text",
            //   WebkitTextFillColor: "transparent",
            //   animation: "gradientAnimation 3s ease-in-out infinite",
            //   fontWeight: "bold",
            //   fontSize: "16px",
            // }}
            style={{ color: "#ffca28" }}
          >
            Quick Links
          </span>
        </Typography>
      </Button>

      {/* Dropdown Menu */}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "16px",
            mt: 1,
            backgroundColor: "#f3e5f5",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            padding: "2px 0",
            width: `${buttonWidth}px`, // Match menu width to button width
          },
        }}
      >
        <MenuItem
          onClick={() => navigate("/dashboard")}
          sx={{
            fontSize: "12px",
            padding: "6px 10px",
            color: "#6200ea",
            backgroundColor: "#e1bee7",
            borderRadius: 2,
            mx: 1,
            my: 0.5,

            "&:hover": {
              backgroundColor: "#f4f4ff",
              color: "#5a00d1",
            },
          }}
        >
          <HomeIcon fontSize="12px" sx={{ mr: 1 }} /> Home
        </MenuItem>
        <MenuItem
          onClick={() => navigate("/depositrequest")}
          sx={{
            fontSize: "12px",
            padding: "6px 10px",
            color: "#6200ea",
            backgroundColor: "#e1bee7",
            borderRadius: 2,
            mx: 1,
            my: 0.5,

            "&:hover": {
              backgroundColor: "#f4f4ff",
              color: "#5a00d1",
            },
          }}
        >
          <CallReceived fontSize="12px" sx={{ mr: 1 }} /> Deposit Requests
        </MenuItem>

        <MenuItem
          onClick={() => navigate("/withdrawrequest")}
          sx={{
            fontSize: "12px",
            padding: "6px 10px",
            color: "#6200ea",
            backgroundColor: "#e1bee7",
            borderRadius: 2,
            mx: 1,
            my: 0.5,

            "&:hover": {
              backgroundColor: "#f4f4ff",
              color: "#5a00d1",
            },
          }}
        >
          <PaymentsIcon fontSize="12px" sx={{ mr: 1 }} /> Withdraw Requests
        </MenuItem>
        <MenuItem
          onClick={() => navigate("/accounts")}
          sx={{
            fontSize: "12px",
            padding: "6px 10px",
            color: "#6200ea",
            backgroundColor: "#e1bee7",
            borderRadius: 2,
            mx: 1,
            my: 0.5,

            "&:hover": {
              backgroundColor: "#f4f4ff",
              color: "#5a00d1",
            },
          }}
        >
          <SwitchAccountIcon fontSize="12px" sx={{ mr: 1 }} /> Direct Accounts
        </MenuItem>
        <MenuItem
          onClick={() => navigate("/wallet-statements")}
          sx={{
            fontSize: "12px",
            padding: "6px 10px",
            color: "#6200ea",
            backgroundColor: "#e1bee7",
            borderRadius: 2,
            mx: 1,
            my: 0.5,

            "&:hover": {
              backgroundColor: "#f4f4ff",
              color: "#5a00d1",
            },
          }}
        >
          <ReceiptLongIcon fontSize="12px" sx={{ mr: 1 }} /> Wallet Statements
        </MenuItem>
        <MenuItem
          onClick={() => navigate("/lower-game-play-details")}
          sx={{
            fontSize: "12px",
            padding: "6px 10px",
            color: "#6200ea",
            backgroundColor: "#e1bee7",
            borderRadius: 2,
            mx: 1,
            my: 0.5,

            "&:hover": {
              backgroundColor: "#f4f4ff",
              color: "#5a00d1",
            },
          }}
        >
          <AssessmentIcon fontSize="14px" sx={{ mr: 1 }} /> CB Report
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default QuickLinksMenu;
