import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import moment from "moment/moment";
import IconButton from "@mui/material/IconButton";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { useAppStore } from "../../../appStore";
import { useCalculateAmount } from "../../../services/gameCalculationService";
import { GameTimeCalculation } from "../../../utils/time";
import { base_url } from "../../../apiUtils";
import Loader from "../../../utils/loader";
import Games from "../../../components/menu/Games";
import ConfirmationDialog from "../../../components/Game/confirmationDialog";
import Sidebar from "../../../components/sidebar/sidebar";

const validationSchema = yup.object({
  openFigure: yup
    .string()
    .matches(/^[0-9]+$/, "Open figure must be a number")
    .required("Number is required"),
  // closeFigure: yup
  //   .string()
  //   .matches(/^[0-9]+$/, "Close figure must be a number")
  //   .required("Close figure is required"),
  gamePoints: yup
    .string()
    .matches(/^[0-9]+$/, "Game points must be a number")
    .required("Game points are required"),
});

function FamilyPanaNumbers() {
  const formik = useFormik({
    initialValues: {
      openFigure: "",
      closeFigure: "",
      gamePoints: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      handleFormSubmit(values);
    },
  });
  // const [selectedGame, setSelectedGame] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const {
    successDialog,
    closeDialog,
    setSuccessDialog,
    loading,
    setLoading,
    selectedGame,
    setSelectedGame,
    games,
    setGames,
    selectedMenuId,
  } = useAppStore();
  const navigate = useNavigate();
  const [totalAmount, setTotalAmount] = useState(0);
  const [enteredAmount, setEnteredAmount] = useState("");
  const [numbers, setNumbers] = useState([]);
  const [isOneWay, setIsOneWay] = useState(true);
  const [openTime, setOpentime] = useState();
  const { gameTiming } = GameTimeCalculation();
  const [amounts, setAmounts] = useState({});
  const [checkNumbers, setCheckNumbers] = useState([]);
  const [validNumber, setValidNumber] = useState();
  const [numberValidated, setNumberValidated] = useState(false);
  const [openFigureError, setOpenFigureError] = useState("");
  const [isNumberValid, setIsNumberValid] = useState(false);
  const [conFirmLoading, setConfirmLoading] = useState(false);

  const [requestData, setRequestData] = useState({
    userInSubmission: 0,
    game: "",
    menu: "",
    bets: [],
    totalBetsAmount: 0,
  });

  const { calculateAmount } = useCalculateAmount();

  const handleCalculate = () => {
    calculateAmount(requestData);
  };

  useEffect(() => {
    setLoading(true);
    const delay = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(delay);
  }, []);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleConfirmationDialogOpen = async () => {
    setConfirmLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setConfirmLoading(false);
    setSuccessDialog(true);
    setOpenDialog(false);
    // Reset form values
    formik.resetForm();
    // setSelectedGame({});
    handleCalculate();
  };
  const handleFormSubmit = async (values) => {
    // Check if number has been validated
    if (!numberValidated) {
      // Notify the user to validate the number first
      toast.warning("Please validate the number first.");
      return;
    }
    try {
      let token = sessionStorage.getItem("accesstoken");
      const response = await axios.post(
        base_url + "menuformation/family-pana",
        {
          number: values.openFigure,
        },
        {
          headers: {
            accesstoken: token,
          },
        }
      );
      const result = response.data;
      console.log("API Response:", result);

      const generatedNumbers = isOneWay ? result.uniqueSet : result.fullArray;
      setNumbers(generatedNumbers);

      const initialAmounts = generatedNumbers.reduce((acc, number) => {
        acc[number] = values.gamePoints;
        return acc;
      }, {});
      setAmounts(initialAmounts);

      const total = values.gamePoints * generatedNumbers.length;
      setTotalAmount(total);
      handleDialogOpen();

      setRequestData((prevData) => ({
        ...prevData,
        menu: selectedMenuId,
        game: selectedGame._id,
        bets: generatedNumbers.map((number) => ({
          number,
          amount: Number(values.gamePoints),
        })),
        totalBetsAmount: total,
        userInSubmission: total,
      }));
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const handleAmountChange = (number, value) => {
    // Parse the input value as a number
    const parsedValue = Number(value) || 0;
    if (parsedValue === 0) {
      toast.warning("minimum amount should be 1");
    }
    // Calculate the change in amount for the specific number
    const amountChange = parsedValue - (amounts[number] || 0);

    // Update the amounts state
    setAmounts((prevAmounts) => ({
      ...prevAmounts,
      [number]: parsedValue,
    }));

    // Update the total based on the amount change
    setTotalAmount((prevTotal) => prevTotal + amountChange);

    setRequestData((prevData) => ({
      ...prevData,
      bets: prevData.bets.map((bet) =>
        bet.number === number ? { ...bet, amount: parsedValue } : bet
      ),
      totalBetsAmount: prevData.totalBetsAmount + amountChange,
      userInSubmission: prevData.userInSubmission + amountChange,
    }));
  };

  const handleDelete = (index) => {
    // Create a copy of the current numbers state
    const updatedNumbers = [...numbers];

    // Get the number and its associated amount at the specified index
    const deletedNumber = updatedNumbers[index];
    const deletedAmount = amounts[deletedNumber] || 0;

    // Remove the element at the specified index
    updatedNumbers.splice(index, 1);

    // Update the state with the modified array
    setNumbers(updatedNumbers);

    // Subtract the deleted amount from the total amount
    const newTotalAmount = totalAmount - deletedAmount;
    setTotalAmount(newTotalAmount);

    // Remove the amount associated with the deleted number
    setAmounts((prevAmounts) => {
      const newAmounts = { ...prevAmounts };
      delete newAmounts[deletedNumber];
      return newAmounts;
    });

    setRequestData((prevData) => ({
      ...prevData,
      bets: prevData.bets.filter((bet) => bet.number !== deletedNumber),
      totalBetsAmount: prevData.totalBetsAmount - deletedAmount,
      userInSubmission: prevData.userInSubmission - deletedAmount,
    }));
  };

  const handleOpenFigureChange = async (e) => {
    let value = e.target.value.replace(/[^0-9]/g, "");
    value = value.slice(0, 3);
    formik.handleChange({
      target: { name: "openFigure", value },
    });
    setOpenFigureError("");

    if (!value) {
      setIsNumberValid(false);
      setNumberValidated(false);
      setCheckNumbers([]);
    } else {
      setValidNumber(value);
      const isValid = await handleValidateNumber(value);
      setIsNumberValid(isValid);
      setNumberValidated(isValid);
    }
  };

  const handleValidateNumber = async (value) => {
    const isValidPattern =
      parseInt(value[0]) > 0 && // First digit is greater than 0
      parseInt(value[1]) === 0 && // Second digit is 0
      parseInt(value[2]) === 0; // Third digit is 0

    if (value === "000" || isValidPattern) {
      try {
        let token = sessionStorage.getItem("accesstoken");
        const response = await axios.post(
          base_url + "menuformation/family-pana",
          {
            number: value,
          },
          {
            headers: {
              accesstoken: token,
            },
          }
        );
        const result = response.data;

        // Update the checkNumbers state based on the API response
        setCheckNumbers(isOneWay ? [result.uniqueSet] : [result.fullArray]);
        setOpenFigureError(""); // Clear the error message if validation succeeds
        return true;
      } catch (error) {
        console.error("Error:", error.message);
        setOpenFigureError("Error occurred while validating the number.");
        return false;
      }
    } else {
      // Handle regular validation for other values
      if (value.length !== 3) {
        setOpenFigureError("Pana should be 3 digits long.");
        return false;
      }

      const a = parseInt(value[0]);
      const b = parseInt(value[1]);
      const c = parseInt(value[2]);

      if (a === 0 || (b === 0 && c > 0)) {
        setOpenFigureError("Wrong Pana");
        return false;
      }

      if (!(a <= b && b <= c) && c !== 0) {
        setOpenFigureError("Wrong Pana");
        return false;
      }

      if (b < a && c === 0) {
        setOpenFigureError("Wrong Pana");
        return false;
      }

      try {
        let token = sessionStorage.getItem("accesstoken");
        const response = await axios.post(
          base_url + "menuformation/family-pana",
          {
            number: value,
          },
          {
            headers: {
              accesstoken: token,
            },
          }
        );
        const result = response.data;

        // Update the checkNumbers state based on the API response
        setCheckNumbers(isOneWay ? [result.uniqueSet] : [result.fullArray]);
        setOpenFigureError(""); // Clear the error message if validation succeeds
        return true;
      } catch (error) {
        console.error("Error:", error.message);
        setOpenFigureError("Error occurred while validating the number.");
        return false;
      }
    }
  };

  // const handleValidateNumber = async (value) => {
  //   if (value.length !== 3) {
  //     setOpenFigureError("Pana should be 3 digits long.");
  //     return false;
  //   }

  //   const a = parseInt(value[0]);
  //   const b = parseInt(value[1]);
  //   const c = parseInt(value[2]);

  //   // if (a === 0 && b === 0 && c === 0) {
  //   //   return true;
  //   // }

  //   if (a === 0 || (b === 0 && c > 0)) {
  //     setOpenFigureError("Wrong Pana");
  //     return false;
  //   }

  //   if (!(a <= b && b <= c) && c !== 0) {
  //     setOpenFigureError("Wrong Pana");
  //     return false;
  //   }

  //   try {
  //     let token = sessionStorage.getItem("accesstoken");
  //     const response = await axios.post(
  //       base_url + "menuformation/family-pana",
  //       {
  //         number: value,
  //       },
  //       {
  //         headers: {
  //           accesstoken: token,
  //         },
  //       }
  //     );
  //     const result = response.data;

  //     // Update the checkNumbers state based on the API response
  //     setCheckNumbers(isOneWay ? [result.uniqueSet] : [result.fullArray]);
  //     console.log(result);
  //     setOpenFigureError(""); // Clear the error message if validation succeeds
  //     return true;
  //   } catch (error) {
  //     console.error("Error:", error.message);
  //     setOpenFigureError("Error occurred while validating the number.");
  //     return false;
  //   }
  // };

  const fecthTime = async () => {
    const openingTime = await gameTiming();
    console.log("closingTime", openingTime);
    setOpentime(openingTime);
  };

  useEffect(() => {
    fecthTime();
  }, [selectedGame]);
  return (
    <>
      {loading ? (
        <Modal open={loading}>
          <Box>
            <Loader />
          </Box>
        </Modal>
      ) : (
        <Box sx={{ display: "flex" }}>
          <Sidebar />
          <Box component="main" sx={{ flexGrow: 1, pt: 3, pr: 2 }}>
            <Box height={65} />
            <Grid container>
              <Grid item sm={12} md={12}>
                {/* game */}
                <Box mb={3} sx={{ display: "flex", alignItems: "center" }}>
                  <ArrowBackIcon
                    sx={{
                      color: "#0d47a1",
                      border: "1px solid #0d47a1",
                      borderRadius: 2,
                      "&:hover": {
                        backgroundColor: "#0d47a1",
                        color: "#ffffff",
                      },
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate("/playGame");
                      setSelectedGame({});
                    }}
                  />
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: { xs: "20px", md: "24px" },
                      ml: 1,
                      color: "#1565c0",
                    }}
                  >
                    Family Pana
                  </Typography>

                  <Box ml={4}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Date :{" "}
                      <span style={{ color: "red" }}>
                        {moment().format("DD-MM-YYYY")}
                      </span>
                    </Typography>
                  </Box>
                </Box>
                <Games type={"pana"} />
                {/* <Grid container spacing={4}>
            {games.map((i, index) => (
              <Grid item xs={3} sm={2} key={index + 1}>
                <Chip
                  label={i}
                  sx={{
                    borderRadius: 2,
                    justifySelf: "center",
                    fontWeight: "bold",
                    letterSpacing: 0.5,
                    backgroundColor:
                      selectedGame === index ? "#f1c40.length === 0,
                    color: selectedGame === index ? "#00000.length === 0,
                    "&:hover": {
                      backgroundColor:
                        selectedGame === index ? "#f1c40f" : "#f1c40f",
                    },
                  }}
                  onClick={() => handleChipClick(index)}
                />
              </Grid>
            ))}
          </Grid> */}
                {/* open time */}
                <Box
                  mt={4}
                  sx={{
                    display: "flex",
                    justifyContent: {
                      xs: "space-between",
                      sm: "flex-start",
                    },
                  }}
                >
                  <Box>
                    <Typography sx={{ fontWeight: "bold", mb: 0.5 }}>
                      Close Time
                    </Typography>
                    <Box
                      sx={{
                        border: "2px solid #bdbdbd",
                        borderRadius: 1,
                        width: "110px",
                        p: 1,
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "red",
                          fontWeight: "bold",
                          letterSpacing: 0.5,
                          fontSize: "18px",
                        }}
                      >
                        {openTime || "00:00"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                {/* game combination */}
                <Box mt={3}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: { xs: "14px", md: "18px" },
                      mb: 2,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Enter The Pana :{" "}
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <TextField
                        size="small"
                        placeholder="Enter Number"
                        name="openFigure"
                        sx={{ ml: { xs: 1, sm: 2, md: 2 } }}
                        onChange={handleOpenFigureChange}
                        value={formik.values.openFigure}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.openFigure &&
                          Boolean(formik.errors.openFigure)
                        }
                        helperText={
                          formik.touched.openFigure && formik.errors.openFigure
                        }
                        InputProps={{
                          endAdornment: isNumberValid ? (
                            <InputAdornment position="end">
                              <CheckIcon sx={{ color: "green" }} />
                            </InputAdornment>
                          ) : (
                            <InputAdornment position="end">
                              <ClearIcon sx={{ color: "red" }} />
                            </InputAdornment>
                          ),
                          style: {
                            fontWeight: "bold",
                            letterSpacing: 0.5,
                            fontSize: "16px",
                          },
                          maxLength: 3,
                        }}
                        disabled={Object.keys(selectedGame).length === 0}
                      />
                    </Box>
                    {/* <Button
                  variant="contained"
                  // onClick={handleValidateNumber}
                  sx={{ ml: 1, textTransform: "capitalize" }}
                >
                  Family
                </Button> */}
                  </Typography>
                  {openFigureError && (
                    <Box sx={{ pl: { xs: 4, sm: 17, md: 20 }, mt: -2 }}>
                      <Typography variant="caption" sx={{ color: "red" }}>
                        {openFigureError} <br />
                        <Typography variant="caption" sx={{ color: "red" }}>
                          Ex: [123 / 113 / 122 / 111 / 110 / 100]
                        </Typography>
                      </Typography>
                    </Box>
                  )}

                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={4}></Grid>

                    <Grid item xs={12} sm={6} md={4}></Grid>
                    <Grid container spacing={{ xs: 0, md: 16 }}>
                      <Grid item xs={12} sm={4}></Grid>
                      <Grid item xs={12} sm={4}>
                        {isNumberValid && (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              mt: 2,
                              border: "1px dotted #bdbdbd",
                            }}
                          >
                            <Typography variant="body2">
                              Family Panas:{" "}
                              {checkNumbers.length > 0 ? (
                                <>
                                  <Grid container>
                                    <Grid item xs={6}>
                                      <ul>
                                        {checkNumbers.map((group) =>
                                          group
                                            .slice(0, 4)
                                            .map((i, index) => (
                                              <li key={index + 1}>{i}</li>
                                            ))
                                        )}
                                      </ul>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <ul>
                                        {checkNumbers.map((group) =>
                                          group
                                            .slice(4)
                                            .map((i, index) => (
                                              <li key={index + 2}>{i}</li>
                                            ))
                                        )}
                                      </ul>
                                    </Grid>
                                  </Grid>
                                </>
                              ) : (
                                <Typography variant="body2">
                                  {checkNumbers.map((i) =>
                                    i.map((item, index) => (
                                      <ul key={index + 3}>
                                        <li>{item}</li>
                                      </ul>
                                    ))
                                  )}
                                </Typography>
                              )}
                            </Typography>
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={4}></Grid>
                    </Grid>
                  </Grid>

                  <Box mt={{ xs: 1, sm: 4 }} sx={{ textAlign: "center" }}>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="Enter Game Points"
                      name="gamePoints"
                      sx={{
                        width: { xs: "100%", sm: "100%", md: "400px" },
                      }}
                      onChange={(e) => {
                        // Ensure only numeric input
                        const value = e.target.value.replace(/[^0-9]/g, "");
                        formik.handleChange({
                          target: { name: "gamePoints", value },
                        });
                      }}
                      value={formik.values.gamePoints}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.gamePoints &&
                        Boolean(formik.errors.gamePoints)
                      }
                      helperText={
                        formik.touched.gamePoints && formik.errors.gamePoints
                      }
                      InputProps={{
                        style: {
                          fontWeight: "bold",
                          letterSpacing: 0.5,
                          fontSize: "16px",
                        },
                      }}
                      disabled={Object.keys(selectedGame).length === 0}
                    />
                  </Box>

                  <Box mt={3} sx={{ textAlign: "center" }}>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={formik.handleSubmit}
                      sx={{
                        backgroundImage: `linear-gradient(#3FABFF, #006BFF)`,

                        "&:hover": {
                          backgroundImage: `linear-gradient(#3FABFF, #006BFF)`,
                        },
                        textTransform: "capitalize",
                        letterSpacing: 1,
                        // fontWeight: "bold",
                        fontSize: "16px",
                        width: { xs: "100%", sm: "100%", md: "250px" },
                      }}
                      disabled={Object.keys(selectedGame).length === 0}
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid item sm={12} md={6}></Grid>
            </Grid>
          </Box>
        </Box>
      )}

      {/* Dialog */}
      <Dialog open={openDialog}>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <DialogTitle sx={{ fontWeight: "bold" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {/* <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
                  Final
                </Typography> */}

                <Typography sx={{ color: "red", fontWeight: "bold" }}>
                  {selectedGame.gameName}
                </Typography>
                <Typography mx={1} fontWeight={"bold"}>
                  {openTime?.openTime}
                </Typography>
                <Typography mx={1}>{moment().format("DD-MM-YYYY")}</Typography>
              </Box>
            </DialogTitle>

            <DialogActions>
              <Button onClick={handleDialogClose} color="error">
                <CloseIcon />
              </Button>
            </DialogActions>
          </Box>
          <DialogContent sx={{ mt: -1 }}>
            {/* Add content for the dialog body */}
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "14px" }}>Numbers</Typography>
                <Typography sx={{ fontSize: "14px" }}>Amount</Typography>
                <Typography sx={{ fontSize: "14px" }}>Action</Typography>
              </Box>
              <Box
                sx={{
                  px: 0.5,
                  maxHeight: "210px",
                  overflowY: "hidden",
                  "&:hover": {
                    overflowY: "auto",
                  },
                  "&::-webkit-scrollbar": {
                    width: "6px",
                    display: "none",
                  },
                  "&:hover::-webkit-scrollbar": {
                    display: "block",
                    background: "transparent",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: `#e9e9e9`,
                    borderRadius: "4px",
                  },
                }}
              >
                {numbers.map((i, index) => (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      border: "1px dashed #bdbdbd",
                      borderRadius: 1,
                      p: 0.5,
                      my: 1,
                    }}
                  >
                    <Typography sx={{ color: "#000000", fontWeight: "bold" }}>
                      {i}
                    </Typography>
                    <TextField
                      size="small"
                      value={amounts[i]}
                      onChange={(e) => handleAmountChange(i, e.target.value)}
                      sx={{ width: "60px", ml: 1 }}
                    />
                    <CloseIcon
                      onClick={() => handleDelete(index)}
                      sx={{
                        color: "#9e9e9e",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
            <Box
              mt={2}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ color: "red" }}>Total:</Typography>
              {numbers.length === 0 ? (
                <Typography sx={{ fontWeight: "bold", ml: 1 }}>
                  {totalAmount.toFixed(2)}
                </Typography>
              ) : (
                <Typography
                  sx={{
                    fontWeight: "bold",
                    ml: 1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {/* {numbers.length} x {enteredAmount} ={" "} */}
                  <p style={{ color: "red", marginLeft: 4 }}>
                    {totalAmount.toFixed(2)}
                  </p>
                </Typography>
              )}
            </Box>

            <Box mt={2}>
              <Button
                fullWidth
                variant="contained"
                onClick={handleConfirmationDialogOpen}
                sx={{
                  textTransform: "capitalize",
                  letterSpacing: 1,
                  fontWeight: "bold",
                  backgroundColor: "#FFB320",
                  "&:hover": {
                    backgroundColor: "#FFB320",
                  },
                }}
                disabled={totalAmount === 0}
              >
                {conFirmLoading ? (
                  <TailSpin
                    color="#ffffff"
                    width={25}
                    height={"auto"}
                    strokeWidth={4}
                  />
                ) : (
                  "Confirm Submission"
                )}
              </Button>
            </Box>
            <Box mt={1}>
              {/* <Typography
                variant="caption"
                color="error"
                sx={{ fontSize: "12px", fontWeight: "bold", letterSpacing: 1 }}
              >
                Warning !!
              </Typography> */}
              <Typography
                variant="caption"
                color="error"
                sx={{ fontSize: "12px", display: "block" }}
              >
                After confirm submission,<b>no changes will be done</b>.
              </Typography>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
      <Dialog open={successDialog}>
        <ConfirmationDialog />
      </Dialog>
    </>
  );
}

export default FamilyPanaNumbers;
