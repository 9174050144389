import { number } from "yup";
import { create } from "zustand";
import { persist } from "zustand/middleware";

let appStore = (set) => ({
  openSnackbar: false,
  openSnackbarError: false,
  snackbarMessage: "",
  profileData: {},
  userOpen: false,
  users: [],
  userId: "",
  addPointOpen: false,
  WithdrawOpen: false,
  requestId: "",
  withdrawId: "",
  raiseDepositRequestOpen: false,
  raiseWithdrawRequestOpen: false,
  depositOpen: false,
  deposit1Open: false,
  depositRevertOpen: false,
  withdrawRequestOpen: false,
  withdrawRequest1Open: false,
  depositRow: [],
  withdrawRow: [],
  games: [],
  menus: [],
  userDefaultBalance: number,
  maximumWithdrawAmount: number,
  minimumWithdrawAmount: number,
  minimumWalletBalance: number,
  walletBalance: "",
  role: "",
  userDetails: {
    disabled: false,
  },
  partialAmount: "",
  date: "",
  depositSent: false,
  gamesSelected: [],
  successDialog: false,
  selectedGame: {},
  allInOne: false,
  setAllInOne: (value) => set((state) => ({ allInOne: value })),
  directDeposit: false,
  setDirectDeposit: (value) => set((state) => ({ directDeposit: value })),
  selectedGamesPlaysBet: [],
  setSelectedGamesPlaysBet: (value) =>
    set((state) => ({ selectedGamesPlaysBet: value })),
  // addOrRemoveGame: (game) =>
  //   set((state) => {
  //     const gameNameToKeep = game.gameName;

  //     // Check if the game being added contains " C"
  //     const containsC = gameNameToKeep.includes(" C");

  //     // Conditionally update selectedGamesPlaysBet
  //     let selectedGamesPlaysBet;
  //     if (containsC) {
  //       // Keep only the game with " C" and remove others with " C"
  //       selectedGamesPlaysBet = [game];
  //     } else {
  //       // Remove games with " C" and add the new game
  //       selectedGamesPlaysBet = state.selectedGamesPlaysBet.filter(
  //         (g) => !g.gameName.includes(" C")
  //       );
  //       selectedGamesPlaysBet.push(game);
  //     }

  //     return { selectedGamesPlaysBet };
  //   }),
  addOrRemoveGame: (game) =>
    set((state) => {
      const newGameName = game.gameName;
      const containsC = newGameName.includes(" C");

      let selectedGamesPlaysBet;

      if (containsC) {
        // If the new game contains " C", keep only this game and remove others
        selectedGamesPlaysBet = [game];
      } else {
        // Remove all games with " C"
        selectedGamesPlaysBet = state.selectedGamesPlaysBet.filter(
          (g) => !g.gameName.includes(" C")
        );

        // Extract the family identifier (first two characters, or another logic you prefer)
        const getFamily = (name) => name.split(" ")[0]; // Adjust if needed
        const newGameFamily = getFamily(newGameName);

        // Check if the selected games contain a different family
        const hasDifferentFamily = selectedGamesPlaysBet.some(
          (g) => getFamily(g.gameName) !== newGameFamily
        );

        if (hasDifferentFamily) {
          // Remove all previous family games if a different family game comes in
          selectedGamesPlaysBet = selectedGamesPlaysBet.filter(
            (g) => getFamily(g.gameName) === newGameFamily
          );
        }

        // Ensure the new game is not already selected before adding
        const alreadySelected = selectedGamesPlaysBet.some(
          (g) => g.gameName === newGameName
        );

        if (!alreadySelected) {
          selectedGamesPlaysBet.push(game);
        }
      }

      return { selectedGamesPlaysBet };
    }),

  // Usage example:
  // addOrRemoveId('someGameId');

  selectedNumbers: [],
  selectedNumbers1: [],
  selectedNumbers2: [],
  selectedMenuInfo: {},
  superAgent: 0,
  bookie: 0,
  agent: 0,
  subAgent: 0,
  customer: 0,
  selectedMenuFormation: [],
  filteredMenus: [],
  myGames: [],
  backendPage: 1,
  rowsPerPage: 5,
  setBackendPage: (value) => set((state) => ({ backendPage: value })),
  setRowsPerPage: (value) => set((state) => ({ rowsPerPage: value })),
  fullGames: [],
  count: 0,
  winningRatio: {
    superAgent: [],
    bookie: [],
    agent: [],
    subAgent: [],
    customer: [],
  },

  setWinningRatio: (value) => set((state) => ({ winningRatio: value })),

  addOrUpdateWinningRatio: (role, type, ratio) =>
    set((state) => {
      console.log(role, type, ratio);
      // Ensure the role exists in the winningRatio state
      const currentRoleArray = state.winningRatio[role] || [];

      // Update the existing items or add new ones
      const updatedRole = currentRoleArray.map((item) =>
        item.type === type ? { ...item, ratio } : item
      );

      // Check if the type already exists in the role array
      const typeExists = updatedRole.some((item) => item.type === type);

      // If the type does not exist, add a new entry
      if (!typeExists) {
        updatedRole.push({ type, ratio });
      }

      return {
        winningRatio: {
          ...state.winningRatio,
          [role]: updatedRole,
        },
      };
    }),

  creditLimit: "",
  setCreditLimit: (value) => set((state) => ({ creditLimit: value })),

  userType: "",
  setUserType: (value) => set((state) => ({ userType: value })),

  betLength: 0,
  setBetLength: (value) => set((state) => ({ betLength: value })),

  setCount: (value) => set((state) => ({ count: value })),
  setFullGames: (value) => set((state) => ({ fullGames: value })),

  appliedPercentage: null,
  setAppliedPercentage: (value) =>
    set((state) => ({ appliedPercentage: value })),

  originalConsolidatedReport: [],
  setOriginalConsolidatedReport: (value) =>
    set((state) => ({ originalConsolidatedReport: value })),

  calculationReport: [],
  setCalculationReport: (value) =>
    set((state) => ({ calculationReport: value })),
  consolidatedReport: [],
  setConsolidatedReport: (value) =>
    set((state) => ({ consolidatedReport: value })),
  dashboardConsolidatedReport: [],
  setDashboardConsolidatedReport: (value) =>
    set((state) => ({ dashboardConsolidatedReport: value })),
  bettingcountReport: [],
  setBettingcountReport: (value) =>
    set((state) => ({ bettingcountReport: value })),
  wordReport: [],
  setWordReport: (value) => set((state) => ({ wordReport: value })),
  excelReport: [],
  setExcelReport: (value) => set((state) => ({ excelReport: value })),
  originalExcelReport: [],
  setOriginalExcelReport: (value) => set((state) => ({ excelReport: value })),

  openTableAmounts: [],
  setOpenTableAmounts: (value) => set((state) => ({ openTableAmounts: value })),

  recentlyRegisteredUserDetails: {},
  setRecentlyRegisteredUserDetails: (value) =>
    set((state) => ({ recentlyRegisteredUserDetails: value })),

  openPermissionModal: false,
  setOpenPermissionModal: (value) =>
    set((state) => ({ openPermissionModal: value })),

  jodiTableAmounts: [],
  setJodiTableAmounts: (value) => set((state) => ({ jodiTableAmounts: value })),

  originalWordReport: [],
  setOriginalWordReport: (value) =>
    set((state) => ({ originalWordReport: value })),

  savedReports: [],
  setSavedReports: (value) => set((state) => ({ savedReports: value })),

  singleOpenResult: "",
  setSingleOpenResult: (value) => set((state) => ({ singleOpenResult: value })),

  jodiLoadRatio: "",
  setJodiLoadRatio: (value) => set((state) => ({ jodiLoadRatio: value })),

  jodiLoadChartAmounts: [],
  setJodiLoadChartAmounts: (value) =>
    set((state) => ({ jodiLoadChartAmounts: value })),

  selectedUser: null,
  setSelectedUser: (value) => set((state) => ({ selectedUser: value })),

  generatedReportsData: [],
  setGeneratedReportsData: (newReport) =>
    set((state) => ({
      generatedReportsData: [...state.generatedReportsData, newReport],
    })),
  turnAccounts: [],
  setTurnAccounts: (value) => set((state) => ({ turnAccounts: value })),
  generatedReports: [],
  setGeneratedReports: (value) => set((state) => ({ generatedReports: value })),
  resultModalOpen: false,
  preClosingTimeId: "",
  setPreClosingTimeId: (value) => set((state) => ({ preClosingTimeId: value })),
  preClosingModalOpen: false,
  setPreClosingModalOpen: (value) =>
    set((state) => ({ preClosingModalOpen: value })),
  openModal: false,
  setOpenModal: (value) => set((state) => ({ openModal: value })),
  updatePreClosingModalOpen: false,
  setUpdatePreClosingModalOpen: (value) =>
    set((state) => ({ updatePreClosingModalOpen: value })),
  setResultModalOpen: (value) => set((state) => ({ resultModalOpen: value })),
  setMyGames: (value) => set((state) => ({ myGames: value })),
  setFilteredMenus: (value) => set((state) => ({ filteredMenus: value })),
  deSelectedMenuFormation: [],
  setDeSelectedMenuFormation: (value) =>
    set((state) => ({ deSelectedMenuFormation: value })),
  deSelectGames: (menu, games) => {
    set((state) => {
      const menuIndex = state.deSelectedMenuFormation.findIndex(
        (selectedMenu) => selectedMenu.menu === menu
      );

      if (menuIndex !== -1) {
        // Menu with menuId exists, update it

        const updatedMenuFormation = [...state.deSelectedMenuFormation];
        updatedMenuFormation[menuIndex] = { menu, games };
        return { deSelectedMenuFormation: updatedMenuFormation };
      } else {
        // Menu with menu doesn't exist, add a new object

        return {
          deSelectedMenuFormation: [
            ...state.deSelectedMenuFormation,
            { menu, games },
          ],
        };
      }
    });
  },
  setSelectedMenuFormation: (value) =>
    set((state) => ({ selectedMenuFormation: value })),
  updateOrAddMenu: (menu, games) => {
    set((state) => {
      const menuIndex = state.selectedMenuFormation.findIndex(
        (selectedMenu) => selectedMenu.menu === menu
      );

      if (menuIndex !== -1) {
        // Menu with menuId exists, update it

        const updatedMenuFormation = [...state.selectedMenuFormation];
        updatedMenuFormation[menuIndex] = { menu, games };
        return { selectedMenuFormation: updatedMenuFormation };
      } else {
        // Menu with menu doesn't exist, add a new object

        return {
          selectedMenuFormation: [
            ...state.selectedMenuFormation,
            { menu, games },
          ],
        };
      }
    });
  },
  removeMenuById: (menuId) => {
    set((state) => {
      const menuIndex = state.selectedMenuFormation.findIndex(
        (selectedMenu) => selectedMenu.menu === menuId
      );

      if (menuIndex !== -1) {
        const updatedMenuFormation = [...state.selectedMenuFormation];
        updatedMenuFormation.splice(menuIndex, 1);
        return { selectedMenuFormation: updatedMenuFormation };
      } else {
        return { selectedMenuFormation: [...state.selectedMenuFormation] };
      }
    });
  },
  loading: false,
  setLoading: (value) => set((state) => ({ loading: value })),
  selectMenusOpen: false,
  setSelectMenusOpen: (value) => set((state) => ({ selectMenusOpen: value })),
  setMenus: (value) => set((state) => ({ menus: value })),
  updateWinningRatio: (role, value) => set((state) => ({ [role]: value })),
  setSelectedMenuInfo: (value) => set((state) => ({ selectedMenuInfo: value })),
  setSelectedNumbers: (value) => set((state) => ({ selectedNumbers: value })),
  setSelectedNumbers1: (value) => set((state) => ({ selectedNumbers1: value })),
  setSelectedNumbers2: (value) => set((state) => ({ selectedNumbers2: value })),
  toggleNumber: (number) =>
    set((state) => ({
      selectedNumbers: state.selectedNumbers.includes(number)
        ? state.selectedNumbers.filter((num) => num !== number)
        : [...state.selectedNumbers, number],
    })),
  selectedNumbers1: [],
  toggleNumber1: (number) =>
    set((state) => ({
      selectedNumbers1: state.selectedNumbers1.includes(number)
        ? state.selectedNumbers1.filter((num) => num !== number)
        : [...state.selectedNumbers1, number],
    })),
  toggleNumber2: (number) =>
    set((state) => ({
      selectedNumbers2: state.selectedNumbers2.includes(number)
        ? state.selectedNumbers2.filter((num) => num !== number)
        : [...state.selectedNumbers2, number],
    })),
  setSelectedGame: (value) => set((state) => ({ selectedGame: value })),
  setSuccessDialog: (value) => set((state) => ({ successDialog: value })),
  // closeDialog: () => set((state) => ({ successDialog: false })),
  results: [],
  setResults: (value) => set((state) => ({ results: value })),
  loading: false,
  setLoading: (value) => set((state) => ({ loading: value })),
  selectedMenuId: "",
  setSelectedMenuId: (value) => set((state) => ({ selectedMenuId: value })),
  setGamesSelected: (value) => set((state) => ({ gamesSelected: value })),
  menusSelected: [],
  setMenusSelected: (value) => set((state) => ({ menusSelected: value })),
  setDepositSent: (value) => set((state) => ({ depositSent: value })),
  setGames: (value) => set((state) => ({ games: value })),
  setDate: (value) => set((state) => ({ date: value })),
  setRole: (value) => set((state) => ({ role: value })),
  setPartialAmount: (value) => set((state) => ({ partialAmount: value })),
  setUserDetailsUpdate: (fieldName, value) =>
    set((state) => ({
      userDetails: {
        ...state.userDetails, // Preserve other fields
        [fieldName]: value, // Update the specified field
      },
    })),
  hackerDialogOpen: false,
  setHackerDialogOpen: (value) => set((state) => ({ hackerDialogOpen: value })),
  shareId: "",
  setShareId: (value) => set((state) => ({ shareId: value })),
  userReport: [],
  setUserReport: (value) => set((state) => ({ userReport: value })),
  userPermissions: [],
  setUserPermissions: (value) => set((state) => ({ userPermissions: value })),
  userDetailOpen: false,
  setRaiseWithdrawRequestOpen: (value) =>
    set((state) => ({ raiseWithdrawRequestOpen: value })),
  setRaiseDepositRequestOpen: (value) =>
    set((state) => ({ raiseDepositRequestOpen: value })),
  setDepositRevertOpen: (value) =>
    set((state) => ({ depositRevertOpen: value })),
  setUserDetailOpen: (value) => set((state) => ({ userDetailOpen: value })),
  setUserDetails: (value) => set((state) => ({ userDetails: value })),
  setWalletBalance: (value) => set((state) => ({ walletBalance: value })),
  setMinimumWalletBalance: (value) =>
    set((state) => ({ minimumWalletBalance: value })),
  setMinimunWithdrawAmount: (value) =>
    set((state) => ({ minimumWithdrawAmount: value })),
  setMaximumWithdrawAmount: (value) =>
    set((state) => ({ maximumWithdrawAmount: value })),
  setUserDefaultBalance: (value) =>
    set((state) => ({ userDefaultBalance: value })),
  setWithdrawRow: (value) => set((state) => ({ withdrawRow: value })),
  setWithdrawRequestOpen: (value) =>
    set((state) => ({ withdrawRequestOpen: value })),
  setWithdrawRequest1Open: (value) =>
    set((state) => ({ withdrawRequest1Open: value })),
  setDepositRow: (value) => set((state) => ({ depositRow: value })),
  setDepositOpen: (value) => set((state) => ({ depositOpen: value })),
  setDeposit1Open: (value) => set((state) => ({ deposit1Open: value })),
  setRequestId: (value) => set((state) => ({ requestId: value })),
  setWithdrawId: (value) => set((state) => ({ withdrawId: value })),
  setUserOpen: (value) => set((state) => ({ userOpen: value })),
  setUserId: (value) => set((state) => ({ userId: value })),
  setAddPointOpen: (value) => set((state) => ({ addPointOpen: value })),
  setWithdrawOpen: (value) => set((state) => ({ WithdrawOpen: value })),
  setUsers: (value) => set((state) => ({ users: value })),
  setProfileData: (value) => set((state) => ({ profileData: value })),
  setOpenSnackbar: (value) => set((state) => ({ openSnackbar: value })),
  setOpenSnackbarError: (value) =>
    set((state) => ({ openSnackbarError: value })),
  setSnackbarMessage: (value) => set((state) => ({ snackbarMessage: value })),
  selectedDateHistory: "",
  setSelectedDateHistory: (value) =>
    set((state) => ({ selectedDateHistory: value })),
  betDialogOpen: false,
  setBetDialogOpen: (value) => set((state) => ({ betDialogOpen: value })),
});

appStore = persist(appStore, {
  name: "mathadminstore",
  getStorage: () => sessionStorage,
});
export const useAppStore = create(appStore);
