// src/api.js
import axios from "axios";
import { base_url } from "../../apiUtils";
const accessToken = sessionStorage.getItem("accesstoken");

// Upload photo API - Adjusted to handle FormData
export const uploadPhoto = async (formData) => {
  try {
    const response = await axios.post(`${base_url}sendPhoto/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        accesstoken: accessToken, // Correct token reference
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error uploading photo:", error);
    throw error;
  }
};

// Mark photo as sent API
export const markPhotoAsSent = async (photoId) => {
  try {
    await axios.patch(
      `${base_url}sendPhoto/mark-as-sent/${photoId}`,
      {},
      {
        headers: {
          accesstoken: accessToken, // Correct token reference
        },
      }
    );
  } catch (error) {
    console.error("Error marking photo as sent:", error);
    throw error;
  }
};

// Get photos for a specific user
export const getPhotosForUser = async (userId) => {
  console.log("userId", userId);
  try {
    const response = await axios.get(
      `${base_url}sendPhoto/for-user/${userId}`,
      {
        headers: {
          accesstoken: accessToken, // Correct token reference
        },
      }
    );
    console.log("responsePhoto", response);
    return response.data;
  } catch (error) {
    console.error("Error retrieving photos for user:", error);
    throw error;
  }
};
