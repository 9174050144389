import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  TablePagination,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import axios from "axios";
import { useAppStore } from "../../appStore";
import { base_url } from "../../apiUtils";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#dde7f1",
    color: "#477BA1",
    border: "2px solid #bdbdbd !important",
    fontWeight: "bold",
    letterSpacing: 0.5,
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: "2px solid #bdbdbd !important",
    textAlign: "center",
    padding: 8,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F7F9FB",
  },
  "&:last-child td, &:last-child th": {
    borderBottom: 0,
  },
}));

const LowerGameDetails = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userNames, setUserNames] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const { selectedDateHistory, setSelectedDateHistory } = useAppStore();

  const getData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${base_url}game/get-transactions?date=${selectedDateHistory}`,
        {
          headers: { accesstoken: sessionStorage.getItem("accesstoken") },
        }
      );
      const finalReport = response.data.transactions;
      setUserNames([...new Set(finalReport.map((item) => item.requestedUser))]); // Extract unique user names
      setTimeout(() => {
        setData(finalReport);
        setLoading(false);
      }, 2000); // Simulate loading for 2 seconds
    } catch (err) {
      console.log("Error fetching data:", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedDateHistory) {
      getData();
    }
  }, [selectedDateHistory]);

  useEffect(() => {
    setSelectedDateHistory("");
    setData([]);
  }, []);

  useEffect(() => {
    if (selectedUser) {
      setFilteredData(
        data.filter((item) => item.requestedUser === selectedUser)
      );
    } else {
      setFilteredData(data);
    }
  }, [data, selectedUser]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
  };

  return (
    <>
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Typography>Filter User:</Typography>
        <FormControl sx={{ minWidth: 120, ml: 1 }}>
          <InputLabel>User</InputLabel>
          <Select
            value={selectedUser}
            onChange={handleUserChange}
            label="User Name"
          >
            <MenuItem value="">All</MenuItem>
            {userNames.map((userName) => (
              <MenuItem key={userName} value={userName}>
                {userName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <TableContainer
        sx={{
          maxHeight: 650,
          overflowY: "auto",
          borderTop: "2px solid #bdbdbd", // Add border to the top of TableContainer
          mt: 3,
        }}
      >
        <Table>
          <TableHead sx={{ position: "sticky", top: 0, zIndex: 1 }}>
            <TableRow>
              <StyledTableCell>Sl.No</StyledTableCell>
              <StyledTableCell>User Name</StyledTableCell>
              <StyledTableCell>Game</StyledTableCell>
              <StyledTableCell>Number</StyledTableCell>
              <StyledTableCell>Bet Point</StyledTableCell>
              <StyledTableCell>W / R</StyledTableCell>
              <StyledTableCell>Payment</StyledTableCell>
              <StyledTableCell>Date</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <StyledTableRow>
                <StyledTableCell colSpan={8} align="center">
                  <CircularProgress />
                </StyledTableCell>
              </StyledTableRow>
            ) : filteredData.length === 0 ? (
              <StyledTableRow>
                <StyledTableCell
                  colSpan={8}
                  align="center"
                  sx={{
                    color: "red",
                    fontWeight: "bold",
                    background: "#fff !important",
                    fontSize: "18px",
                  }}
                >
                  Report Not Found .. 😔
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              (rowsPerPage > 0
                ? filteredData.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredData
              ).map((gameDetails, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>
                    {page * rowsPerPage + index + 1}
                  </StyledTableCell>
                  <StyledTableCell>{gameDetails.requestedUser}</StyledTableCell>
                  <StyledTableCell>{gameDetails.game}</StyledTableCell>
                  <StyledTableCell>{gameDetails.number}</StyledTableCell>
                  <StyledTableCell>
                    {Number(gameDetails.userInSubmission) % 1 === 0
                      ? Number(gameDetails.userInSubmission) // No decimals, display as is
                      : Number(gameDetails.userInSubmission).toFixed(2)}{" "}
                  </StyledTableCell>

                  <StyledTableCell>{gameDetails.winningRatio}</StyledTableCell>
                  <StyledTableCell
                    sx={{
                      color: gameDetails.requestedUserOutPayment > 0 && "green",
                      fontWeight:
                        gameDetails.requestedUserOutPayment > 0 && "bold",
                    }}
                  >
                    {gameDetails.requestedUserOutPayment}
                  </StyledTableCell>
                  <StyledTableCell>{gameDetails.date}</StyledTableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
};

export default LowerGameDetails;
