import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Badge, Button, Typography } from "@mui/material";
import { Logout } from "@mui/icons-material";
import { useAppStore } from "../../appStore";
import { styled } from "@mui/material/styles";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export default function ProfileMenu() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [profileDetails, setProfileDetails] = useState(null);
  const { profileData } = useAppStore();
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getRoleAvatar = () => {
    const role = profileData?.role;
    if (role === "systemowner") {
      return "SO"; // System Owner
    } else if (role === "superagent") {
      return "SA"; // Super Agent
    } else if (role === "bookie") {
      return "BK"; // Bookie
    } else if (role === "agent") {
      return "AG"; // Agent
    } else if (role === "subagent") {
      return "SAg"; // Sub Agent
    } else if (role === "customer") {
      return "C"; // Customer
    }
    return "U"; // Unknown or Default
  };
  const handleLogout = () => {
    sessionStorage.removeItem("isMathLoggedin");

    sessionStorage.removeItem("mathadminstore");
    sessionStorage.removeItem("accesstoken");
    navigate("/");
    // toast.success("logged out successfully");
  };

  return (
    <React.Fragment>
      <>
        <Box sx={{ display: "flex", alignItems: "center", textAlign: "left" }}>
          <Button
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              variant="dot"
            >
              <Avatar sx={{ background: "#fdd835", p: 2 }}>
                {getRoleAvatar()}
              </Avatar>
            </StyledBadge>
            <Box
              sx={{
                display: { xs: "none", md: "contents" },
                ml: { xs: 0, sm: 2 },
                background: "red",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    color: "#000000",
                    textAlign: "left",
                    textTransform: "capitalize",
                    fontSize: "14px",
                    fontWeight: 500,
                  }}
                >
                  {profileData?.name}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ textAlign: "left", textTransform: "capitalize" }}
                >
                  {profileData?.role}
                </Typography>
              </Box>
            </Box>
            <ArrowDropDownIcon />
          </Button>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem
            onClick={() => navigate("/myProfile")}
            sx={{ fontWeight: "bold" }}
          >
            My Profile
          </MenuItem>

          <Divider />

          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </>
    </React.Fragment>
  );
}
