import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  tableCellClasses,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { base_url } from "../../apiUtils";
import { useAppStore } from "../../appStore";
import moment from "moment";
import "moment/locale/en-in";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#dde7f1",
    color: "#477BA1",
    border: "2px solid #bdbdbd !important",
    fontWeight: "bold",
    letterSpacing: 0.5,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: "2px solid #bdbdbd !important",
    padding: 8,
    wordBreak: "break-word",
    whiteSpace: "normal",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F7F9FB",
  },
  "&:last-child td, &:last-child th": {
    borderBottom: 0,
  },
}));

const isSameDigitOfLengthTwo = (number) => {
  const strNumber = number.toString();
  return strNumber.length === 2 && strNumber[0] === strNumber[1];
};

const HierarchyAccountDetails = () => {
  const [data, setData] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const { selectedDateHistory, setSelectedDateHistory } = useAppStore();

  const getData = async () => {
    try {
      const url = `${base_url}game/hierarchy-account-details?date=${selectedDateHistory}`;

      const response = await axios.get(url, {
        headers: { accesstoken: sessionStorage.getItem("accesstoken") },
      });
      const finalReport = response.data.accountDetails;
      // const formattedData = finalReport.flatMap((userReport) =>
      //   userReport.Records.map((record) => ({
      //     ...record,
      //     UserName: userReport.UserName,
      //   }))
      // );
      setData(finalReport);
    } catch (err) {
      console.log("Error fetching data:", err);
    }
  };

  useEffect(() => {
    getData();
  }, [selectedDateHistory]);

  useEffect(() => {
    setSelectedDateHistory("");
    setData([]);
  }, []);

  // const getBaseGame = (game) => {
  //   const match = game.match(/^[^\s]*/);
  //   return match ? match[0] : game;
  // };

  const filteredData = selectedUser
    ? data.filter((item) => item.UserName === selectedUser)
    : data;

  const uniqueUsers = Array.from(new Set(data.map((item) => item.UserName)));

  let serialNumber = 1;

  return (
    <Box sx={{ mb: 4 }}>
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Typography sx={{ mr: 3 }}>Filter User:</Typography>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>User Name</InputLabel>
          <Select
            value={selectedUser}
            onChange={(e) => setSelectedUser(e.target.value)}
            label="User Name"
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            {uniqueUsers.map((user, index) => (
              <MenuItem key={index} value={user}>
                {user}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ maxWidth: { sm: "75%", xl: "100%" }, mt: 3 }}>
        <TableContainer
          sx={{
            maxHeight: 650,
            overflowY: "auto",
            borderTop: "2px solid #bdbdbd", // Add border to the top of TableContainer
          }}
        >
          <Table aria-label="customized table">
            <TableHead
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                backgroundColor: "#dde7f1", // Ensure header has background color to stand out
              }}
            >
              <TableRow>
                <StyledTableCell align="center">Sl No</StyledTableCell>
                <StyledTableCell align="center">Date</StyledTableCell>
                <StyledTableCell align="center">User Name</StyledTableCell>
                <StyledTableCell align="center">Game</StyledTableCell>
                {/* <StyledTableCell align="center">Result</StyledTableCell>
                <StyledTableCell align="center">Type</StyledTableCell> */}
                <StyledTableCell align="center">Vyapar</StyledTableCell>
                <StyledTableCell align="center">Commission</StyledTableCell>
                <StyledTableCell align="center">Risk / LD</StyledTableCell>
                <StyledTableCell align="center">Sharing</StyledTableCell>
                <StyledTableCell align="center">Out Submission</StyledTableCell>
                <StyledTableCell align="center">Winning Number</StyledTableCell>
                <StyledTableCell align="center">Payment</StyledTableCell>
                <StyledTableCell align="center">CR-Loss</StyledTableCell>
                <StyledTableCell align="center">
                  Share-Loss / Profit
                </StyledTableCell>
                <StyledTableCell align="center">Net Balance</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.length === 0 ? (
                <StyledTableRow>
                  <StyledTableCell
                    colSpan={16}
                    align="center"
                    sx={{
                      color: "red",
                      fontWeight: "bold",
                      background: "#fff !important",
                      fontSize: "18px",
                    }}
                  >
                    Report Not Found .. 😔
                  </StyledTableCell>
                </StyledTableRow>
              ) : (
                data?.map((row, index) => {
                  // const isDifferentUserOrGame =
                  //   index === 0 ||
                  //   filteredData[index].UserName !==
                  //     filteredData[index - 1].UserName ||
                  //   getBaseGame(filteredData[index].Game) !==
                  //     getBaseGame(filteredData[index - 1].Game);
                  return (
                    <StyledTableRow key={index}>
                      <>
                        <StyledTableCell
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {index + 1}
                        </StyledTableCell>

                        <StyledTableCell
                          align="center"
                          // rowSpan={
                          //   filteredData.filter(
                          //     (r) =>
                          //       r.UserName === row.UserName &&
                          //       getBaseGame(r.Game) === getBaseGame(row.Game)
                          //   ).length
                          // }
                          style={{
                            border: "1px solid black",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {moment(row.Date).format("L")}
                        </StyledTableCell>

                        <StyledTableCell
                          align="center"
                          // rowSpan={
                          //   filteredData.filter(
                          //     (r) =>
                          //       r.UserName === row.UserName &&
                          //       getBaseGame(r.Game) === getBaseGame(row.Game)
                          //   ).length
                          // }
                          style={{
                            border: "1px solid black",
                            textTransform: "capitalize",
                          }}
                        >
                          {row.userId?.name}
                        </StyledTableCell>

                        <StyledTableCell
                          align="center"
                          // rowSpan={
                          //   filteredData.filter(
                          //     (r) =>
                          //       r.UserName === row.UserName &&
                          //       getBaseGame(r.Game) === getBaseGame(row.Game)
                          //   ).length
                          // }
                          style={{ border: "1px solid black" }}
                        >
                          {row?.game}
                          {/* {getBaseGame(row.Game)} */}
                        </StyledTableCell>
                      </>

                      {/* <StyledTableCell
                        align="center"
                        style={{ border: "1px solid black" }}
                      >
                        {(row.Type?.includes("O") && row?.OpenResult) ||
                          (row.Type?.includes("C") && row?.CloseResult) ||
                          row.Result}
                      </StyledTableCell> */}
                      {/* <StyledTableCell
                        align="center"
                        style={{ border: "1px solid black" }}
                      >
                        {row.Type}
                      </StyledTableCell> */}
                      <StyledTableCell
                        align="center"
                        style={{ border: "1px solid black" }}
                      >
                        {Number(row?.vyapar)?.toFixed(2)}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ border: "1px solid black" }}
                      >
                        {Number(row?.commission)?.toFixed(2)}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ border: "1px solid black" }}
                      >
                        {Number(row?.risk)?.toFixed(2)}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{
                          border: "1px solid black",
                          color:
                            (row?.ShareType === "minus" && "red") ||
                            (row?.ShareType === "plus" && "green"),
                        }}
                      >
                        {row.Sharing}{" "}
                        {(row?.ShareType === "minus" && "(-)") ||
                          (row?.ShareType === "plus" && "(+)")}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ border: "1px solid black" }}
                      >
                        {row.UserRole === "systemowner"
                          ? "-"
                          : Number(row?.outSubmission)?.toFixed(2)}
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        // style={{
                        //   color:
                        //     row.winningNumber === "N/A"
                        //       ? "#ffab00"
                        //       : isSameDigitOfLengthTwo(row.winningNumber)
                        //       ? "red"
                        //       : "green",
                        //   fontSize: "16px",
                        // }}
                      >
                        {row.winningNumber}
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        style={{
                          border: "1px solid black",
                          color: row.Payment < 0 ? "red" : "green",
                        }}
                      >
                        {Number(row?.Payment)?.toFixed(2)}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{
                          border: "1px solid black",
                          color: "red",
                        }}
                      >
                        {Number(row?.CRLoss)?.toFixed(2)}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{
                          border: "1px solid black",
                          color: "#000",
                        }}
                      >
                        {Number(row?.ShareLoss)?.toFixed(2)}
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        // rowSpan={
                        //   filteredData.filter(
                        //     (r) =>
                        //       r.UserName === row.UserName &&
                        //       getBaseGame(r.Game) === getBaseGame(row.Game)
                        //   ).length
                        // }
                        style={{
                          border: "1px solid black",
                          fontWeight: "bold",
                          color: row.NetBalance < 0 ? "red" : "green",
                          fontSize: "14px",
                        }}
                      >
                        {row?.NetBalance?.toFixed(2)}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default HierarchyAccountDetails;
