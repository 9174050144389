// import React, { useEffect, useState } from "react";
// import { useAppStore } from "../../appStore";
// import axios from "axios";
// import { base_url } from "../../apiUtils";
// import { Chip, Grid } from "@mui/material";
// import io from "socket.io-client";

// const Games = () => {
//   const {
//     setSelectedGame,
//     selectedGame,
//     myGames,
//     setMyGames,
//     selectedMenuId,
//     profileData,
//   } = useAppStore();
//   const [filteredGames, setFilteredGames] = useState([]);
//   const [fetchedGames, setFetchedGames] = useState([]);

//   let token = sessionStorage.getItem("accesstoken");

//   useEffect(() => {
//     const socket = io("http://localhost:80"); // Replace with your server URL

//     const userId = profileData?._id; // Replace with the actual user ID
//     if (userId) {
//       socket.emit("joinRoom", userId);

//       socket.on("selectedGamesUpdated", (data) => {
//         console.log("SelectedGames document updated:", data);
//         getSelectGames(); // Refetch games to reflect the updates
//       });
//     }

//     return () => {
//       socket.disconnect(); // Clean up the socket connection on unmount
//     };
//   }, [profileData?._id]); // Dependency on user ID

//   const getSelectGames = () => {
//     axios
//       .get(`${base_url}game/get-myGames-populate/${selectedMenuId}`, {
//         headers: {
//           accesstoken: token,
//         },
//       })
//       .then((response) => {
//         console.log("API Response:", response.data);
//         let data = response?.data?.games;
//         setFetchedGames(data);
//         let individualGames = response?.data?.individualGameOff;
//         // let hierarchyGames = response?.data?.hierarchyGameOff;
//         // let role;
//         // if (profileData?.role === "superagent") {
//         //   role = "superAgent";
//         // } else if (profileData?.role === "subagent") {
//         //   role = "subAgent";
//         // } else {
//         //   role = profileData?.role;
//         // }

//         // if (response.data.status === "success") {
//         //   const currentTime = new Date(); // Get the current time
//         //   const systemDay = currentTime.toLocaleDateString("en-US", {
//         //     weekday: "long",
//         //   }); // Get the current day of the week as a string

//         //   const filteredGames = data.filter((game) => {
//         //     const matchingDay = game.weekTimings?.some((timing) => {
//         //       const isCorrectDay =
//         //         timing.day === systemDay && timing.holiday === false;

//         //       if (isCorrectDay) {
//         //         const [hours, minutes] = timing.time.split(":");
//         //         const gameTime = new Date(currentTime);
//         //         gameTime.setHours(parseInt(hours), parseInt(minutes), 0, 0);

//         //         return gameTime > currentTime;
//         //       }

//         //       return false;
//         //     });

//         //     return matchingDay;
//         //   });

//         //   console.log("Filtered Games after initial filtering:", filteredGames);

//         //   const games = filteredGames.filter((game) => {
//         //     const matchingIndividualGame = individualGames.find(
//         //       (individualGame) => individualGame?.game === game._id
//         //     );
//         //     const matchingHierarchy = hierarchyGames.find(
//         //       (hierarchyGame) => hierarchyGame?.game === game._id
//         //     );
//         //     if (matchingIndividualGame) {
//         //       return matchingIndividualGame.isActive === true;
//         //     } else if (matchingHierarchy) {
//         //       return matchingHierarchy.single === true;
//         //     } else {
//         //       return game.isActive[role] === true;
//         //     }
//         //   });

//         //   console.log("Games after isActive filtering:", games);

//         //   setMyGames(games);
//         //}
//       })
//       .catch((error) => {
//         console.log("API Error:", error);
//         setMyGames([]);
//       });
//   };

//   useEffect(() => {
//     const filterGames = () => {
//       const currentTime = new Date();
//       const systemDay = currentTime.toLocaleDateString("en-US", {
//         weekday: "long",
//       }); // Get the current day (0-6, where 0 is Sunday)

//       console.log("Current Time:", currentTime);
//       console.log("System Day:", systemDay);

//       const filtered = fetchedGames.filter((game) => {
//         const matchingDay = game.weekTimings?.some((timing) => {
//           const isCorrectDay =
//             timing.day === systemDay && timing.holiday === false;

//           if (isCorrectDay) {
//             const [hours, minutes] = timing.time.split(":");
//             const gameTime = new Date(currentTime);
//             gameTime.setHours(parseInt(hours), parseInt(minutes), 0, 0);

//             return gameTime > currentTime;
//           }

//           return false;
//         });

//         return matchingDay;
//       });

//       console.log("Filtered Games:", filtered);
//       setMyGames(filtered);
//     };

//     // Run filterGames every minute
//     const interval = setInterval(filterGames, 60000);

//     // Run it immediately on mount
//     filterGames();

//     // Clean up the interval on unmount
//     return () => clearInterval(interval);
//   }, [fetchedGames]);

//   useEffect(() => {
//     if (selectedMenuId && profileData) {
//       getSelectGames();
//     }
//   }, [selectedMenuId, profileData]); // Refetch games when selectedMenuId or profileData changes

//   const handleChipClick = (game) => {
//     setSelectedGame(game);
//   };

//   return (
//     <div>
//       <Grid container spacing={{ xs: 1, md: 4 }}>
//         {myGames?.map((i, index) => (
//           <Grid item xs={3} sm={2} key={index + 1}>
//             <Chip
//               label={i?.gameName}
//               sx={{
//                 borderRadius: 2,
//                 justifySelf: "center",
//                 fontWeight: "bold",
//                 letterSpacing: 0.5,
//                 backgroundColor:
//                   selectedGame._id === i._id ? "#b3e5fc" : undefined,
//                 color: selectedGame._id === i._id ? "#000000" : undefined,
//                 "&:hover": {
//                   backgroundColor:
//                     selectedGame._id === i._id ? "#b3e5fc" : "#b3e5fc",
//                 },
//               }}
//               onClick={() => handleChipClick(i)}
//             />
//           </Grid>
//         ))}
//       </Grid>
//     </div>
//   );
// };

// export default Games;
import React, { useEffect, useState, useRef } from "react";
import { useAppStore } from "../../appStore";
import axios from "axios";
import { base_url } from "../../apiUtils";
import { Chip, Grid } from "@mui/material";
import io from "socket.io-client";

const Games = ({ type }) => {
  const {
    setSelectedGame,
    selectedGame,
    myGames,
    setMyGames,
    selectedMenuId,
    profileData,
  } = useAppStore();
  const [hierarchyGame, setHierarchyGame] = useState();
  const [fetchedGames, setFetchedGames] = useState([]);
  const [specialPreClosing, setSpecialPreClosing] = useState([]);
  const intervalRef = useRef(null); // Define intervalRef with useRef
  let role;
  if (profileData?.role === "superagent") {
    role = "superAgent";
  } else if (profileData?.role === "subagent") {
    role = "subAgent";
  } else {
    role = profileData?.role;
  }

  let token = sessionStorage.getItem("accesstoken");

  useEffect(() => {
    const socket = io("http://localhost:5000"); // Replace with your server URL

    const userId = profileData?._id; // Replace with the actual user ID
    if (userId) {
      socket.emit("joinRoom", userId);

      socket.on("selectedGamesUpdated", (data) => {
        console.log("SelectedGames document updated:", data);
        getSelectGames(); // Refetch games to reflect the updates
      });
    }

    return () => {
      socket.disconnect(); // Clean up the socket connection on unmount
    };
  }, [profileData?._id]);

  const getSelectGames = () => {
    axios
      .get(`${base_url}game/get-myGames-populate/${selectedMenuId}`, {
        headers: {
          accesstoken: token,
        },
      })
      .then((response) => {
        console.log("API Response:", response.data);
        let data = response?.data?.games;
        setFetchedGames(data);
        setSpecialPreClosing(response?.data?.preClosing);
        setHierarchyGame(response?.data?.hierarchyGameOffIds);
      })
      .catch((error) => {
        console.log("API Error:", error);
        setMyGames([]);
      });
  };
  function findPreClosingByGameId(preClosing, gameId) {
    // Use the find() method to locate the object with the matching gameId
    const result = preClosing.find((item) => item.gameId === gameId);
    return result || null; // Return the found object or null if not found
  }
  useEffect(() => {
    // Clear previous state and cancel the previous interval when menu changes
    setFetchedGames([]);
    setMyGames([]);

    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    if (selectedMenuId && profileData) {
      getSelectGames();
    }
  }, [selectedMenuId, profileData]);

  useEffect(() => {
    const filterGames = () => {
      let filteredHierarchyGames = [];
      console.log("games before update", fetchedGames);
      if (hierarchyGame && fetchedGames.length > 0) {
        const updatedGames = fetchedGames?.filter((game) => {
          // Check if there is any item in hierarchyGameOff where both the game ID matches
          // and the type (such as single, jodi, pana) is set to false
          console.log("type", type);
          const matchingHierarchy = hierarchyGame.find((hg) => {
            return (
              hg.game === game._id &&
              ((type === "single" && hg.single === false) ||
                (type === "jodi" && hg.jodi === false) ||
                (type === "pana" && hg.pana === false))
            );
          });

          console.log("matchingHierarchy", matchingHierarchy);

          // Exclude games with a matching ID and type in hierarchyGameOff
          return !matchingHierarchy;
        });

        console.log("Updated Games (after removal):", updatedGames);
        filteredHierarchyGames = updatedGames; // Assuming you want to store the result in `myGames`
      }
      const currentTime = new Date();
      const systemDay = currentTime.toLocaleDateString("en-US", {
        weekday: "long",
      }); // Get the current day

      const filtered = filteredHierarchyGames?.filter((game) => {
        const matchingDay = game.weekTimings?.some((timing) => {
          const isCorrectDay =
            timing.day === systemDay && timing.holiday === false;

          if (isCorrectDay) {
            const [hours, minutes] = timing.time.split(":");
            const gameTime = new Date(currentTime);
            gameTime.setHours(parseInt(hours), parseInt(minutes), 0, 0);

            // Adjust the gameTime based on preClosingTime for the role
            const preClosing = game.preClosingTime.find(
              (pc) => pc.role === role
            );
            if (preClosing) {
              gameTime.setMinutes(
                gameTime.getMinutes() - parseInt(preClosing.closingTime)
              );

              const getSpecialCase = findPreClosingByGameId(
                specialPreClosing,
                game._id
              );
              if (getSpecialCase) {
                if (getSpecialCase.type === "minus") {
                  gameTime.setMinutes(
                    gameTime.getMinutes() - parseInt(getSpecialCase.minutes)
                  );
                } else {
                  gameTime.setMinutes(
                    gameTime.getMinutes() + parseInt(getSpecialCase.minutes)
                  );
                }
              }
              console.log(gameTime);

              return gameTime > currentTime;
            }
          }

          return false;
        });

        return matchingDay;
      });

      setMyGames(filtered); // Update myGames state
    };

    // Calculate the time until the next minute
    const now = new Date();
    const millisecondsUntilNextMinute =
      60000 - (now.getSeconds() * 1000 + now.getMilliseconds());

    // Run filterGames immediately, then every minute at the start of the minute
    const initialTimeout = setTimeout(() => {
      filterGames();
      intervalRef.current = setInterval(filterGames, 60000); // Save the interval ID
    }, millisecondsUntilNextMinute);

    // Run it immediately on mount
    filterGames();

    // Clean up the timeout and interval on unmount
    return () => {
      clearTimeout(initialTimeout);
      clearInterval(intervalRef.current);
    };
  }, [fetchedGames, profileData.role]);

  const handleChipClick = (game) => {
    setSelectedGame(game);
  };

  return (
    <div>
      <Grid container spacing={{ xs: 1, md: 4 }}>
        {myGames?.map((i, index) => (
          <Grid item xs={3} sm={2} lg={1} key={index + 1}>
            <Chip
              label={i?.gameName}
              sx={{
                borderRadius: 2,
                justifySelf: "center",
                fontWeight: "bold",
                letterSpacing: 0.5,
                backgroundColor:
                  selectedGame._id === i._id ? "#b3e5fc" : undefined,
                color: selectedGame._id === i._id ? "#000000" : undefined,
                "&:hover": {
                  backgroundColor:
                    selectedGame._id === i._id ? "#b3e5fc" : "#b3e5fc",
                },
              }}
              onClick={() => handleChipClick(i)}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Games;
