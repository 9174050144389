import React, { useState } from "react";
import axios from "axios";
import { Box, Button, TextField, Typography } from "@mui/material";
import { base_url } from "./../../apiUtils";

const TextInputWithActions = ({ senderId, onSend, onCancel }) => {
  console.log("data,", senderId, onSend, onCancel);
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSendClick = async () => {
    if (!text.trim()) {
      alert("Please enter some text before sending.");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        `${base_url}sendPhoto/upload-text`,
        {
          senderId,
          textData: text,
        },
        {
          headers: {
            accesstoken: sessionStorage.getItem("accesstoken"),
          },
        }
      );
      console.log("Text uploaded successfully:", response.data);
      setText("");
      if (onSend) onSend(response.data);
    } catch (error) {
      console.error("Error uploading text:", error);
      alert("Failed to upload text. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleCancelClick = () => {
    setText(""); // Clear input
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        mt: 2,
        padding: 2,
        border: "1px solid #ddd",
        borderRadius: 2,
      }}
    >
      <Typography variant="h6" sx={{ mb: 1 }}>
        Paste Data Below:
      </Typography>
      <TextField
        label="Enter or Paste Text"
        variant="outlined"
        multiline
        rows={4}
        value={text}
        onChange={(e) => setText(e.target.value)}
        fullWidth
      />
      <Box sx={{ display: "flex", gap: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSendClick}
          disabled={loading}
        >
          {loading ? "Sending..." : "Send"}
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCancelClick}
          disabled={loading}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default TextInputWithActions;
